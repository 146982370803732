import React, { useState, useEffect } from "react";
import "./App.css";
import Navbar from "./Navbar";
import {
    BrowserRouter as Router,
    Route,
    Routes,
    Navigate,
} from "react-router-dom";
import Signin from "./components/Signin/Signin";
import Ips from "./components/pages/Ips/Ips";
import Lender from "./components/pages/LenderCRUD/Lender";
import Authorization from "./components/pages/Authorization";
import Request from "./components/pages/TipoSolicitudCRUD/TipoSolicitud";
import Admission from "./components/pages/TypeAdmissionCRUD/TipoAdmision";
import ServiceMaster from "./components/pages/TypeServiceCRUD/TipoServicio";
import Calendar from "./components/pages/Calendar";
import Companion from "./components/pages/CompanionCRUD/acompanante";
import Patient from "./components/pages/Patient/Paciente";
import Room from "./components/pages/Room";
import Description from "./components/pages/DescriptionCRUD/DescripcionCubrimiento";
import Form from "./components/pages/Form";
import Reporte from "./components/pages/Reporte";
import TypeCompanion from "./components/pages/TypeCompanionCRUD/TypeCompanion";
import Asignacion from "./components/pages/Assignment/Main";
import ReportBeds from "./components/pages/ReportBeds/ReportBed";
import ReportAssignment from "./components/pages/ReportAssignment/ReportAssignment";
import FormEdition from "./components/pages/FormEdition";
import RepordEdition from "./components/pages/ModificarReporte";
import EditCompanion from "./components/pages/EditCompanion/EditCompanion";
import EditService from "./components/pages/EditService/EditService";
import EspecialCondition from "./components/pages/EspecialCondition/EspecialCondition";
import DeleteReport from "./components/pages/DeleteReport";
import Administration from "./components/pages/Administration/Administration";
import ReporDelete from "./components/pages/ReportDeleteCase/ReportDelete";
import Menu from "./components/pages/Menu/TablaMenu";
import MenuDetail from "./components/pages/MenuDetail/MenuDetail";
import MenuXRol from "./components/pages/MenuRol/MenuRol";
import Users from "./components/pages/Users/users";
import Automata from "./components/pages/RobotReading/Reading";
import TableReferenceServie from "./components/pages/TypeService2CRUD/TypeService2";
import ReporteTraslados from "./components/pages/ReporteTraslados/ReporteTraslados";
import HogarTraslado from "./components/pages/HogarTraslados/HogarTraslado";
import Autorizacion from "./components/pages/ReportAutorizacion/reporteAutorizacion";
import CheckOut from "./components/pages/CheckOut/CheckOut";
import Home from "./components/pages/Home";
import axios from "axios";
import AsignacionTransporte from "./components/pages/AsignarTransporte/asignacionTransporte";
import Conductores from "./components/pages/conductores/conductor";
import Vehiculos from "./components/pages/vehiculos/vehiculos";
import ReasignacionCama from "./components/pages/Reasignacion/reasignacionCamas";
import ChangeDate from "./components/pages/ChangeDate/changeDate";
import ReasignacionHogar from "./components/pages/ReasignarHogar/reasignarHogar";
import Hospitalizacion from "./components/pages/Hospitalizacion/hospitalizacion";
import Reintegros from "./components/pages/Reintregos/reintegros";
import Bitacora from "./components/pages/ReporteBitacora/bitacora";
import FormManual from "./components/pages/formularioManual/formularioManual";
import ReintegroCheckOut from "./components/pages/ReintegroCheckOut/reintegroCheckOut";
import Movimientos from "./components/pages/reportMovimientoCamas/movimiento";
import Cookies from "js-cookie";
import CodigosDescripcion from "./components/pages/codigoDescripcion/codigoDescripcion";
import Tarifas from "./components/pages/Tarifas/tarifas";
import ReporteTransportes from './components/pages/ReporteExcelTraslados/reporteExcelTraslados';
import ReporteFacturacion from "./components/pages/ReporteFacturacion";
import AmpliacionEstadia from "./components/pages/ExtendStay/extendStay";
import Genero from "./components/pages/GenderCRUD/gender";
import Validacion from "./components/pages/ValidacionIngreso/ValidacionIngreso";
import AsignarDocumentoFinal from "./components/pages/EditTemporalNumber/editTemporal";

axios.defaults.baseURL = "https://aclbqa.sisamlat.com/api";
axios.defaults.withCredentials = true;
axios.interceptors.request.use((config) => {
    config.headers["X-Requested-With"] = "XMLHttpRequest";
    return config;
});

function App() {
    const [token, setToken] = useState("");
    const [authLink, setauthLink] = useState("");

    const [loggedIn, setLoggedIn] = useState(
        localStorage.getItem("loggedIn") === "true" || false
    );

    const login = () => {
        setLoggedIn(true);
        setauthLink(true);
        localStorage.setItem("loggedIn", true);
    };

    const handleMenuOf = () => {
        setLoggedIn(false);
        setauthLink(false);
        localStorage.setItem("loggedIn", false);
    };

    useEffect(() => {
        const storedToken = Cookies.get("token");
        if (storedToken) {
            setToken(storedToken);
            setLoggedIn(true);
            setauthLink(true);
        } else {
            setToken("");
            localStorage.setItem("loggedIn", false);
            setLoggedIn(false);
            setauthLink(false);
            localStorage.setItem("session", 0);
        }
    }, []);

    return (
        <Router>
            <div className="App">
                <Navbar authLink={authLink} handleMenuOf={handleMenuOf} />
                <Routes>
                    <Route path="/" element={<Navigate to="/signin" />} />
                    <Route
                        path="/signin"
                        element={<Signin setToken={setToken} login={login} />}
                    />
                    <Route path="/ips" element={<Ips token={token} />} />
                    <Route path="/lender" element={<Lender token={token} />} />
                    <Route
                        path="/authorization"
                        element={<Authorization token={token} />}
                    />
                    <Route
                        path="/request"
                        element={<Request token={token} />}
                    />
                    <Route
                        path="/admission"
                        element={<Admission token={token} />}
                    />
                    <Route
                        path="/servicemaster"
                        element={<ServiceMaster token={token} />}
                    />
                    <Route
                        path="/calendar"
                        element={<Calendar token={token} />}
                    />
                    <Route
                        path="/companion"
                        element={<Companion token={token} />}
                    />
                    <Route
                        path="/patient"
                        element={<Patient token={token} />}
                    />
                    <Route path="/room" element={<Room token={token} />} />
                    <Route
                        path="/description"
                        element={<Description token={token} />}
                    />
                    <Route path="/report" element={<Reporte token={token} />} />
                    <Route path="/form" element={<Form token={token} />} />
                    <Route
                        path="/assignment"
                        element={<Asignacion token={token} />}
                    />
                    <Route
                        path="/reportBeds"
                        element={<ReportBeds token={token} />}
                    />
                    <Route
                        path="/reportAssignment"
                        element={<ReportAssignment token={token} />}
                    />
                    <Route
                        path="/typecompanion"
                        element={<TypeCompanion token={token} />}
                    />
                    <Route
                        path="/formEdition"
                        element={<FormEdition token={token} />}
                    />
                    <Route
                        path="/reportEdition"
                        element={<RepordEdition token={token} />}
                    />
                    <Route
                        path="/editCompanion"
                        element={<EditCompanion token={token} />}
                    />
                    <Route
                        path="/editService"
                        element={<EditService token={token} />}
                    />
                    <Route
                        path="/conditionEspecial"
                        element={<EspecialCondition token={token} />}
                    />
                    <Route
                        path="/deleteForm"
                        element={<DeleteReport token={token} />}
                    />
                    <Route
                        path="/Administration"
                        element={<Administration token={token} />}
                    />
                    <Route path="/Menu" element={<Menu token={token} />} />
                    <Route
                        path="/reportDelete"
                        element={<ReporDelete token={token} />}
                    />
                    <Route
                        path="/menuDetail"
                        element={<MenuDetail token={token} />}
                    />
                    <Route
                        path="/menuXRol"
                        element={<MenuXRol token={token} />}
                    />
                    <Route path="/users" element={<Users token={token} />} />
                    <Route
                        path="/robotLectura"
                        element={<Automata token={token} />}
                    />
                    <Route path="/home" element={<Home token={token} />} />
                    <Route
                        path="/serviceHogar"
                        element={<TableReferenceServie token={token} />}
                    />
                    <Route
                        path="/ReporteTraslados"
                        element={<ReporteTraslados token={token} />}
                    />
                    <Route
                        path="/HogarTraslado"
                        element={<HogarTraslado token={token} />}
                    />
                    <Route
                        path="/Autorizacion"
                        element={<Autorizacion token={token} />}
                    />
                    <Route
                        path="/CerrarCaso"
                        element={<CheckOut token={token} />}
                    />
                    <Route
                        path="/AsignarTransporte"
                        element={<AsignacionTransporte token={token} />}
                    />
                    <Route
                        path="/conductores"
                        element={<Conductores token={token} />}
                    />
                    <Route
                        path="/vehiculos"
                        element={<Vehiculos token={token} />}
                    />
                    <Route
                        path="/reasignarCama"
                        element={<ReasignacionCama token={token} />}
                    />
                    <Route
                        path="/changeDate"
                        element={<ChangeDate token={token} />}
                    />
                    <Route
                        path="/reasignarHogar"
                        element={<ReasignacionHogar token={token} />}
                    />
                    <Route
                        path="/hospitalizacion"
                        element={<Hospitalizacion token={token} />}
                    />
                    <Route
                        path="/reintegros"
                        element={<Reintegros token={token} />}
                    />
                    <Route
                        path="/bitacora"
                        element={<Bitacora token={token} />}
                    />
                    <Route
                        path="/movimiento"
                        element={<Movimientos token={token} />}
                    />
                    <Route
                        path="/formularioManual"
                        element={<FormManual token={token} />}
                    />
                    <Route
                        path="/reintegroCheckOut"
                        element={<ReintegroCheckOut token={token} />}
                    />
                    <Route
                        path="/codigosDescripcion"
                        element={<CodigosDescripcion token={token} />}
                    />
                    <Route
                        path="/actualizarDocTemporal"
                        element={<AsignarDocumentoFinal token={token} />}
                    />
                    <Route
                        path="/reporteTrasladosExcel"
                        element={<ReporteTransportes token={token} />}
                    />
                    <Route
                        path="/generoPaciente"
                        element={<Genero token={token} />}
                    />
                    <Route
                        path="/validacionIngreso"
                        element={<Validacion token={token} />}
                    />
                    <Route
                        path="/facturaciosLossman"
                        element={<ReporteFacturacion token={token} />}
                    /><Route
                    path="/ampliarEstadia"
                    element={<AmpliacionEstadia token={token} />}
                    
                    
                />
                </Routes>
            {/* build */}
            </div>
        </Router>
    );
}

export default App;
