import React, { useState, useEffect, useRef } from "react";
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  FormGroup,
} from "reactstrap";
import Select from "react-select";
import axios from "axios";
import Swal from "sweetalert2";
import BedSelection from "../../ComponentsUse/Select/selectBed";
import { TextField  } from "@material-ui/core";
import Switch from '@mui/material/Switch';
import FormControlLabel from '@mui/material/FormControlLabel';


const ModalCreate = ({ isOpen, toggle, title, formId, token, infoModal, updateTable }) => {
  const [nuevoFormulario, setNuevoFormulario] = useState("");
  const [formMode, setFormMode] = useState("none");
   const modalRef = useRef(null);const [state, setState] = React.useState({
      jason: false,
    });
  

   useEffect(() => {
      setNuevoFormulario(infoModal ? infoModal.NumeroCaso : "");
    }, [infoModal]);

  const handleNumeroFormulario = (event) => {
    setNuevoFormulario
    (event.target.value);
    if (token) {
        if (event.target.value && event.target.value.length >= 4) {
            axios
                .get(`/formulario/numeroCaso/${event.target.value}`, {
                    headers: { Authorization: `Bearer ${token}` },
                })
                .then((response) => {
                    Swal.fire({
                        icon: "error",
                        title: "Error!",
                        text: "El numero de caso ingresado ya esta registrado en sistema",
                        showCloseButton: true,
                    });
                })
                .catch((error) => {});
        }
    }
  };

  const handleCreateSubmit = (event) => {
    event.preventDefault();

    // Enviar los datos del paciente y de los acompañantes
    axios
    .put(
        `/temporal/actualizacion/${formId}`,
        {
            NumeroFormulario: nuevoFormulario,
        },
        { headers: { Authorization: `Bearer ${token}` } }
      )
      .then((response) => {
        Swal.fire({
          icon: "success",
          title: "Creado!",
          text: "se ha modificado el numero de formulario exitosamente",
          showCloseButton: true,
        }).then(() => {
          toggle();
          updateTable();
          setNuevoFormulario("");
        });
      })
      .catch((error) => {
        Swal.fire({
          icon: "error",
          title: "Error!",
          text: "Ha ocurrido un error al actualizar el numero de documento.",
          showCloseButton: true,
        }).then(() => {
          toggle();
          setNuevoFormulario("");
        });
      });
  };

  return (
    <Modal ref={modalRef} isOpen={isOpen} toggle={toggle}>
      <ModalHeader toggle={toggle}>{title}</ModalHeader>
      <ModalBody>
      <FormGroup>
        <TextField
          label="Nuevo número de formulario"
          className="form-control "
          type="text"
          name="numeroFormulario"
          value={nuevoFormulario}
          onChange={handleNumeroFormulario}
        />
      </FormGroup>
      </ModalBody>

      <ModalFooter>
        <Button onClick={handleCreateSubmit} color="primary" otype="submit">
          Actualizar
        </Button>
        <Button color="danger" onClick={() => toggle()}>
          Cancelar
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default ModalCreate;
