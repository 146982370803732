import React, { useState, useEffect, useRef } from "react";
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  FormGroup,
} from "reactstrap";
import axios from "axios";
import Swal from "sweetalert2";
import Select from "react-select";

const ModalCreate = ({
  isOpen,
  toggle,
  title,
  token,
  pacienteId,
  FechaNacimiento,
  generoId,
  Nombre1,
  Apellido1,
  TipoDocumentoId,
  NumeroIdent,

}) => {
  const [formMode, setFormMode] = useState("none");
  const [fechaNacimiento, setFechaNacimiento] = useState("");
  const [genero, SetGenero] = useState([]);
  const [selectedGenero, setSelectedGenero] = useState("");
  const [selectedGenero2, setSElectedGenero2] = useState("");
  const modalRef = useRef(null);

  useEffect(() => {
      setFechaNacimiento(FechaNacimiento);
    }, [FechaNacimiento]);

  useEffect(() => {
    if(token){
    axios
      .get("/genero", { headers: { Authorization: `Bearer ${token}` } })
      .then((response) => {
        SetGenero(response.data);
      })
      .catch((error) => {
        console.log(error);
      });
    }
  }, [token]);

  const generoOptions = genero.map((option) => ({
    value: option.id,
    label: option.Tipo_Genero ?? option.Tip_Genero,
  }));

  const handleGeneroChange = (selectedOption) => {
    setSElectedGenero2(selectedOption.value);
    setSelectedGenero(
      generoOptions.find((option) => option.value === selectedOption.value)
    );
  };

  const handleFechaNacimientoChange = (event) => {
    setFechaNacimiento(event.target.value);
  };

  console.log(FechaNacimiento, "nombre1");
  const handleCreateSubmit = (event) => {
    event.preventDefault();
    axios
      .put(`/paciente/${pacienteId}`, {
        FechaNacimiento: fechaNacimiento ? fechaNacimiento : FechaNacimiento,
        GeneroId : parseInt(selectedGenero2) ? parseInt(selectedGenero2) : parseInt(generoId),
        Nombre1 : Nombre1,
        Apellido1 : Apellido1,
        TipoDocumentoId : TipoDocumentoId,
        NumeroIdent : NumeroIdent,
      }, {headers: { Authorization: `Bearer ${token}` }})
      .then((response) => {
        Swal.fire({
          icon: "success",
          title: "Actualizado!",
          text: "el paciente a sido actualizada con exito.",
          showCloseButton: true,
        }).then(() => {
          window.location.reload();
        });
      })
      .catch((error) => {
        Swal.fire({
          icon: "error",
          title: "Error!",
          text: "el paciente no se pudo actualizar",
          showCloseButton: true,
        });
      });
  };

  return (
    <Modal ref={modalRef} isOpen={isOpen} toggle={toggle}>
      <ModalHeader toggle={toggle}>{title}</ModalHeader>
      <ModalBody>
      <FormGroup>
          <label>Fecha de Nacimiento:</label>
          <input
            className="form-control"
            name="fechaNacimiento"
            type="date"
            onChange={handleFechaNacimientoChange}
            value={fechaNacimiento ? fechaNacimiento : FechaNacimiento}
          />
        </FormGroup>
        <FormGroup>
          <label htmlFor="genero">Genero:</label>
          <Select
            options={generoOptions}
            value={selectedGenero ? selectedGenero : generoOptions.find((option) => option.value === generoId)}
            onChange={handleGeneroChange}
          ></Select>
        </FormGroup>
      </ModalBody>

      <ModalFooter>
        <Button onClick={handleCreateSubmit} color="primary" otype="submit">
          Insertar
        </Button>
        <Button color="danger" onClick={() => setFormMode("none")}>
          Cancelar
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default ModalCreate;
