import React, { useState, useEffect, useRef } from "react";
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  FormGroup,
} from "reactstrap";
import Select from "react-select";
import axios from "axios";
import Swal from "sweetalert2";
import BedSelection from "../../ComponentsUse/Select/selectBed";
import { TextField } from "@material-ui/core";
import Switch from '@mui/material/Switch';
import FormControlLabel from '@mui/material/FormControlLabel';


const ModalCreate = ({ isOpen, toggle, title, formId, token, infoModal, updateTable }) => {

  const [numeroDiasAmpliacion, setNumeroDiasAmpliacion] = useState("");
  const [nuevoFormulario, setNuevoFormulario] = useState("");
  const [fechaInicial, setFechaInicial] = useState("");
  const [fechaFinal, setFechaFinal] = useState("");
  const [numeroMipres, setNumeroMipres] = useState("");
  const [formMode, setFormMode] = useState("none");
  const modalRef = useRef(null);const [state, setState] = React.useState({
    jason: false,
  });
  const [esTemporal, setEsTemporal] = React.useState(false);
  

  const handleDiasAmpliacion = (event) => {
    setNumeroDiasAmpliacion
    (event.target.value);
  };

  const handleNumeroFormulario = (event) => {
    setNuevoFormulario
    (event.target.value);
    if (token) {
      if (event.target.value && event.target.value.length > 4) {
          axios
              .get(`/formulario/numeroCaso/${event.target.value}`, {
                  headers: { Authorization: `Bearer ${token}` },
              })
              .then((response) => {
                  Swal.fire({
                      icon: "error",
                      title: "Error!",
                      text: "El numero de caso ingresado ya esta registrado en sistema",
                      showCloseButton: true,
                  });
              })
              .catch((error) => {});
      }
  }
  };

  const handleNumeroMipres = (event) => {
    setNumeroMipres
    (event.target.value);
  };

  const handleSwitchChange = (event) => {
    const esTemp = event.target.checked;
    setEsTemporal(esTemp);

    setState({
      ...state,
      [event.target.name]: event.target.checked,
    });
  };
    
  useEffect(() => {
    // Si infoModal tiene FechaFinal válida, calcula la nueva fecha inicial
    if (infoModal?.FechaFinal) {
      const nuevaFechaInicial = new Date(infoModal.FechaFinal);
      nuevaFechaInicial.setDate(nuevaFechaInicial.getDate());
      setFechaInicial(nuevaFechaInicial.toISOString().split("T")[0]);
    }
  }, [infoModal]);

  const handleCreateSubmit = (event) => {
    event.preventDefault();

    // Enviar los datos del paciente y de los acompañantes
    axios
      .post(`ampliacion/${formId}`, {
        NumeroDiasAmpliacion: numeroDiasAmpliacion,
        NumeroFormulario: nuevoFormulario,
        NumeroMipres: numeroMipres,
        FechaInicial: fechaInicial,
        FechaFinal: fechaFinal,
      }, {headers: { Authorization: `Bearer ${token}` }})
      .then((response) => {
        Swal.fire({
          icon: "success",
          title: "Creado!",
          text: "se ha ampliado la estadia del usuario con exito",
          showCloseButton: true,
        }).then(() => {
          toggle();
          updateTable();
          setEsTemporal(false);
          setNumeroMipres("");
          setNumeroDiasAmpliacion("");
          setFechaFinal("");
          setNuevoFormulario("");
          setState({ jason: false });
        });
      })
      .catch((error) => {
        Swal.fire({
          icon: "error",
          title: "Error!",
          text: "Ha ocurrido un error al ampliar la estadia.",
          showCloseButton: true,
        }).then(() => {
          toggle();
          setEsTemporal(false);
          setNumeroMipres("");
          setNumeroDiasAmpliacion("");
          setFechaFinal("");
          setNuevoFormulario("");
          setState({ jason: false });
        });
      });
  };

  useEffect(() => {
    if (esTemporal) {
      axios
        .post(
          "/formularios/generar-temporal",
          {
            esTemporal: true,
          },
          { headers: { Authorization: `Bearer ${token}` } } // Configuración de encabezados
        )
        .then((response) => {
          if (response.data.numeroTemporal) {
            setNuevoFormulario(response.data.numeroTemporal); // Actualizar el campo con el número temporal
          }
        })
        .catch((error) => {
          console.error("Error:", error);
        });
    } else {
      setNuevoFormulario(""); // Reiniciar el valor si el switch se desactiva
    }
  }, [esTemporal, token]);  

  return (
    <Modal ref={modalRef} isOpen={isOpen} toggle={toggle}>
      <ModalHeader toggle={toggle}>{title}</ModalHeader>
      <ModalBody>
      <FormControlLabel
          control={
            <Switch checked={state.jason} onChange={handleSwitchChange} name="jason" />
          }
          label="¿Asignación temporal?"
        />
      <FormGroup>
          <TextField
              label="Nuevo número de formulario"
              className="form-control "
              type={esTemporal ? "text" : "number"}
              name="numeroFormulario"
              value={nuevoFormulario}
              onChange={handleNumeroFormulario}
              disabled={esTemporal}
          />
      </FormGroup>
      <FormGroup>
          <TextField
              label="Nuevo número Mipres"
              className="form-control "
              type="text"
              name="numeroMipres"
              value={numeroMipres}
              onChange={handleNumeroMipres}
          />
      </FormGroup>
        <FormGroup>
          <TextField
              label="Cantidad de días de ampliación"
              className="form-control "
              type="Number"
              name="diasAmpliacion"
              value={numeroDiasAmpliacion}
              onChange={handleDiasAmpliacion}
          />
      </FormGroup>
      <FormGroup>
        <label>Fecha Inicial:</label>
        <input
            className="form-control"
            name="fechaInicial"
            type="date"
            onChange={(event) => setFechaInicial(event.target.value)}
            value={ fechaInicial }
            disabled
        />
    </FormGroup>
    <FormGroup>
        <label>Fecha Final:</label>
        <input
            className="form-control"
            name="fechaInicial"
            type="date"
            onChange={(event) => setFechaFinal
              (event.target.value)}
            value={ fechaFinal }
        />
    </FormGroup>
      
      </ModalBody>

      <ModalFooter>
        <Button onClick={handleCreateSubmit} color="primary" otype="submit">
          Insertar
        </Button>
        <Button color="danger" onClick={() => toggle()}>
          Cancelar
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default ModalCreate;
