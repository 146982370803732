import React, { useState, useEffect, useRef } from "react";
import axios from "axios";
import { Button } from "reactstrap";
import { Helmet } from "react-helmet";
import Spinner from "../../Spinner/spinner";
import { AgGridReact } from "ag-grid-react";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-alpine.css";
import { localeEs } from "../../locale/ag-grid-es";
import CustomModal from "./CustomModal.jsx";
import BreadCrum from "../../breadCrum/breadCrum";
import withTokenCheck from "../../withTokenCheck";

const AppAsginacion = ({ token }) => {
    const [data, setData] = useState([]);
    const [modalOpen, setModalOpen] = useState(false);
    const [modalContent, setModalContent] = useState({});
    const [companionsData, setCompanionsData] = useState([]);
    const [selectedHabitaciones, setSelectedHabitaciones] = useState([]);
    const [loading, setLoading] = useState(true);
    const gridApi = useRef(null);
    const etiquetas = [
        "Inicio Lossman",
        "Cambios de Evento",
        "Validación de ingreso al servicio",
    ];

    useEffect(() => {
        if (token) {
            setLoading(true);
            axios
                .get("/validacion", {
                    headers: { Authorization: `Bearer ${token}` },
                })
                .then((response) => {
                    console.log(response.data)
                    setData(response.data);
                    setLoading(false);
                })
                .catch((error) => {
                    console.log(error);
                });
        }
    }, [token]);

    const defaultColDef = {
        sortable: true,
        resizable: true,
        filter: true,
        editable: true,
    };

    const columnDefs = [
        {
            field: "id",
            headerName: "Validación ingreso",
            cellRenderer: (params) => (
                <Button
                    color="secondary"
                    onClick={() => handleEditClick(params.data)}
                >
                    Validación de Ingreso
                </Button>
            ),
        },
        {
            headerName: "Hogar asignado",
            field: "hogar.Nombre",
        },
        { headerName: "Numero de Caso", field: "NumeroCaso" },
        {
            headerName: "Nombre del paciente",
            field: "formulario.paciente.Nombre1",
            cellRenderer: (params) => {
                const nombre1 = params.data.paciente.Nombre1 || "";
                const nombre2 = params.data.paciente.Nombre2 || " ";
                const apellido1 = params.data.paciente.Apellido1 || "";
                const apellido2 = params.data.paciente.Apellido2 || "";
                return (
                    nombre1 + " " + nombre2 + " " + apellido1 + " " + apellido2
                );
            },
        },
        {
            headerName: "tipo de documento",
            field: "paciente.tipo_documento.Texto",
        },
        { headerName: "Numero de documento", field: "paciente.NumeroIdent" },
        { headerName: "Fecha Inicial", field: "FechaInicial" },
        { headerName: "Fecha Final", field: "FechaFinal" },
    ];

    const handleModalToggle = () => {
        setModalOpen(!modalOpen);
        setCompanionsData([]);
        setSelectedHabitaciones([]);
    };

    const handleEditClick = (element) => {
        setModalContent(element);
        handleModalToggle();
    };

    console.log(modalContent, "contenido modal")

    const handleExportCSV = () => {
        gridApi.current.exportDataAsCsv();
    };

    const onGridReady = (params) => {
        gridApi.current = params.api;
    };

    const handleUpdateTable = () => {
        if (token) {
            setLoading(true);
            axios
                .get("/validacion", {
                    headers: { Authorization: `Bearer ${token}` },
                })
                .then((response) => {
                    setData(response.data);
                    setLoading(false);
                })
                .catch((error) => {
                    console.log(error);
                });
        }
    };

    return (
        <>
            <Helmet>
                <title>Validación Ingreso</title>
            </Helmet>
            <div style={{ position: "relative" }}>
                {loading && <Spinner />}
                <BreadCrum etiquetas={etiquetas} />
                <div style={{ opacity: loading ? 0.5 : 1 }}>
                    <div className="container-fluid mt-5">
                        <Button color="success" onClick={handleExportCSV}>
                            Exportar CSV (Excel)
                        </Button>
                        <div
                            className="mt-3"
                            style={{ height: "500px", width: "100%" }}
                        >
                            <div
                                className="ag-theme-alpine"
                                style={{ height: "100%", width: "100%" }}
                            >
                                <AgGridReact
                                    rowData={data}
                                    columnDefs={columnDefs}
                                    defaultColDef={defaultColDef}
                                    pagination={true}
                                    onGridReady={onGridReady}
                                    localeText={localeEs}
                                    paginationPageSize={20}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
           { <CustomModal
                isOpen={modalOpen}
                toggle={handleModalToggle}
                title={modalContent.title}
                content={modalContent.content}
                footer={modalContent.footer}
                companion={modalContent.acompanantes}
                FormId={modalContent.id}
                pacienteId={modalContent.paciente ? modalContent.paciente.id : ""}
                FechaNacimiento={modalContent.paciente ? modalContent.paciente.FechaNacimiento : ""}
                generoId={modalContent.paciente ? modalContent.paciente.GeneroId : ""}
                Nombre1={modalContent.paciente ? modalContent.paciente.Nombre1 : ""}
                Apellido1={modalContent.paciente ? modalContent.paciente.Apellido1 : ""}
                TipoDocumentoId={modalContent.paciente ? modalContent.paciente.TipoDocumentoId : ""}
                NumeroIdent={modalContent.paciente ? modalContent.paciente.NumeroIdent : ""}
                token={token}
                handleUpdateTable={handleUpdateTable}
                companionsData={companionsData}
                setCompanionsData={setCompanionsData}
                selectedHabitaciones={selectedHabitaciones}
                setSelectedHabitaciones={setSelectedHabitaciones}
            />}
        </>
    );
};

export default withTokenCheck(AppAsginacion);
