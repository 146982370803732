import React, { useState, useEffect } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import { Button, FormGroup, Form } from "reactstrap";
import axios from "axios";
import Swal from "sweetalert2";
import Box from "@mui/material/Box";
import FormControl from "@mui/material/FormControl";
import Select from "react-select";
import Divider from "@mui/material/Divider";
import Chip from "@mui/material/Chip";
import { v4 as uuid } from "uuid";
import { TextField } from "@material-ui/core";
import withTokenCheck from "../../withTokenCheck";

const AppForm = ({ token }) => {
    const [fechaSolicitud, setFechaSolicitud] = useState("");
    const [numeroCaso, setNumeroCaso] = useState("");
    const [fechaInicial, setFechaInicial] = useState("");
    const [fechaFinal, setFechaFinal] = useState("");
    const [paciente, setPaciente] = useState([]);
    const [selectedPaciente, setSelectedPaciente] = useState("");
    const [selectedPaciente2, setSelectedPaciente2] = useState("");
    const [nombre1Paciente, setNombre1Paciente] = useState("");
    const [nombre2Paciente, setNombre2Paciente] = useState("");
    const [apellido1Paciente, setApellido1Paciente] = useState("");
    const [apellido2Paciente, setApellido2Paciente] = useState("");
    const [tipoDocumento, setTipoDocumento] = useState([]);
    const [selectedTipoDocumento, setSelectedTipoDocumento] = useState("");
    const [selectedTipoDocumento2, setSelectedTipoDocumento2] = useState("");
    const [numeroIdentificacion, setNumeroIndentificacion] = useState("");
    const [fechaNacimiento, setFechaNacimiento] = useState("");
    const [edad, setEdad] = useState("");
    const [telPaciente, setTelPaciente] = useState("");
    const [ciudadOrigen, setCiudadOrigen] = useState([]);
    const [selectedCiudadOrigen, setSelectedCiudadOrigen] = useState("");
    const [selectedCiudadOrigen2, setSelectedCiudadOrigen2] = useState("");
    const [tipoAcompanante, setTipoAcompanante] = useState([]);
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [acompanante, setAcompanante] = useState([]);
    const [motivo, setMotivo] = useState("");
    const [habilitado, setHabilitado] = useState("");
    const [caracteristicasEspeciales, setCaracteristicasEspeciales] = useState(
        []
    );
    const [checkedState, setCheckedState] = useState({});
    const [numInputsAcom, setNumInputsAcom] = useState(1);
    const [inputsAcom, setInputsAcom] = useState([
        {
            id: uuid(),
            acompanante: "",
            tipoAcompanante: "",
            apellido1Acompanante: "",
            apellido2Acompanante: "",
            nombre1Acompanante: "",
            nombre2Acompanante: "",
            tipoDocumentoAcom: "",
            numeroIdentificacionAcom: "",
            fechaNacimientoAcom: "",
        },
    ]);
    const [numInputs, setNumInputs] = useState(1);
    const [inputs, setInputs] = useState([
        {
            id: uuid(),
            tipoServicio: "",
            Ips: "",
            tipoAdmision: "",
            fechaConsulta: "",
        },
    ]);

    function agregarInput() {
        if (numInputs < 5) {
            setNumInputs(numInputs + 1);
            setInputs([
                ...inputs,
                {
                    id: uuid(),
                    tipoServicio: "",
                    Ips: "",
                    tipoAdmision: "",
                    fechaConsulta: "",
                },
            ]);
        }
    }

   

 





    function agregarInputAcompanante() {
        if (numInputsAcom < 7) {
            setNumInputsAcom(numInputsAcom + 1);
            setInputsAcom([
                ...inputsAcom,
                {
                    id: uuid(),
                    acompanante: "",
                    tipoAcompanante: "",
                    apellido1Acompanante: "",
                    apellido2Acompanante: "",
                    nombre1Acompanante: "",
                    nombre2Acompanante: "",
                    tipoDocumentoAcom: "",
                    numeroIdentificacionAcom: "",
                    fechaNacimientoAcom: "",
                },
            ]);
        }
    }

    function cancelarInputsAcompanante(index) {
        if (numInputsAcom > 0) {
            setNumInputsAcom(numInputsAcom - 1);
            const newInputs = [...inputsAcom];
            newInputs.splice(index, 1);
            setInputsAcom(newInputs);
        }
    }

    function handleInputAcompananteChange(selectedOption, inputId) {
        const label = selectedOption ? selectedOption.label : "";
        const list = inputsAcom.map((input) =>
            input.id === inputId
                ? {
                      ...input,
                      acompanante: selectedOption.value,
                      acompananteLabel: label,
                  }
                : input
        );
        setInputsAcom(list);
    }

    useEffect(() => {
        if (token) {
            axios
                .get("/ciudad", {
                    headers: { Authorization: `Bearer ${token}` },
                })
                .then((response) => {
                    setCiudadOrigen(response.data);
                })
                .catch((error) => {
                    console.log(error);
                });
        }
    }, [token]);

    const ciudadOptions = ciudadOrigen.map((option) => ({
        value: option.id,
        label: option.Texto ?? option.Texto,
    }));

   

    const pacienteOptions = paciente.map((option) => ({
        value: option.id,
        label:
            option.NumeroIdent +
                " " +
                option.Nombre1 +
                " " +
                option.Apellido1 ??
            option.NumeroIdent + " " + option.Nombre1 + " " + option.Apellido1,
    }));

    const tipoDocumentoOptions = tipoDocumento.map((option) => ({
        value: option.id,
        label: option.Texto ?? option.Texto,
    }));

    

    const acompananteOptions = acompanante.map((option) => ({
        value: option.id,
        label:
            option.NumeroIdent +
                " " +
                option.Nombre1 +
                " " +
                option.Apellido1 ??
            option.NumeroIdent + " " + option.Nombre1 + " " + option.Apellido1,
    }));

    // Obtener todas las ciudades al cargar el componente
    
   
    useEffect(() => {
        if (token) {
            axios
                .get("/paciente", {
                    headers: { Authorization: `Bearer ${token}` },
                })
                .then((response) => {
                    setPaciente(response.data);
                })
                .catch((error) => {
                    console.log(error);
                });
        }
    }, [token]);

    useEffect(() => {
        if (token) {
            axios
                .get("/tipodocumento", {
                    headers: { Authorization: `Bearer ${token}` },
                })
                .then((response) => {
                    setTipoDocumento(response.data);
                })
                .catch((error) => {
                    console.log(error);
                });
        }
    }, [token]);


    useEffect(() => {
        if (token) {
            axios
                .get("/ciudad", {
                    headers: { Authorization: `Bearer ${token}` },
                })
                .then((response) => {
                    setCiudadOrigen(response.data);
                })
                .catch((error) => {
                    console.log(error);
                });
        }
    }, [token]);

    useEffect(() => {
        if (token) {
            axios
                .get("/tipoacompanante", {
                    headers: { Authorization: `Bearer ${token}` },
                })
                .then((response) => {
                    setTipoAcompanante(response.data);
                })
                .catch((error) => {
                    console.log(error);
                });
        }
    }, [token]);

    useEffect(() => {
        if (token) {
            axios
                .get("/condicionesespeciales", {
                    headers: { Authorization: `Bearer ${token}` },
                })
                .then((response) => {
                    setCaracteristicasEspeciales(response.data);
                    const initialState = response.data.reduce((acc, item) => {
                        return { ...acc, [item.id]: false };
                    }, {});
                    setCheckedState(initialState);
                })
                .catch((error) => {
                    console.log(error);
                });
        }
    }, [token]);

    // Manejar el envío del formulario para crear una nueva ciudad
    const handleCreateSubmit = (event) => {
        setIsSubmitting(true);
        event.preventDefault();
        axios
            .post(
                "/formulario/manual",
                {
                    NumeroCaso: parseInt(numeroCaso),
                    FechaInicial: fechaInicial,
                    FechaFinal: fechaFinal,
                    PacienteId: parseInt(selectedPaciente2),
                    Acompanantes: inputsAcom,
                },
                { headers: { Authorization: `Bearer ${token}` } }
            )
            .then((response) => {
                Swal.fire({
                    icon: "success",
                    title: "Guardado!",
                    text: "La autorización ha sido guardada con exito.",
                    showCloseButton: true,
                }).then((result) => {
                    if (result.isConfirmed) {
                        window.location.reload();
                    } else if (result.isDenied) {
                        Swal.fire("Changes are not saved", "", "info");
                    }
                });
            })

            .catch((error) => {
                console.log(error);
                var errorMessage = "";
                for (var key in error.response.data.errors) {
                    errorMessage += error.response.data.errors[key] + "<br>";
                }
                Swal.fire({
                    icon: "error",
                    title: error.response.data.message,
                    html: errorMessage,
                });
                setIsSubmitting(false);
            });
    };

    // Manejar el cambio en el campo de descripción
    const handleNumeroCasoChange = (event) => {
        setNumeroCaso(event.target.value);
        if (token) {
            if (event.target.value && event.target.value.length >= 6) {
                axios
                    .get(`/formulario/numeroCaso/${event.target.value}`, {
                        headers: { Authorization: `Bearer ${token}` },
                    })
                    .then((response) => {
                        Swal.fire({
                            icon: "error",
                            title: "Error!",
                            text: "El numero de caso ingresado ya esta registrado en sistema",
                            showCloseButton: true,
                        });
                    })
                    .catch((error) => {});
            }
        }
    };
 
    useEffect(() => {
        if (fechaFinal && fechaInicial && fechaFinal < fechaInicial) {
            Swal.fire({
                icon: "error",
                title: "Error!",
                text: "Ingrese una fecha valida, la Fecha final no puede ser menor a la inicial",
                showCloseButton: true,
            });
        }
    }, [fechaFinal, fechaInicial])
    
    const handleFechaInicialChange = (event) => {
        setFechaInicial(event.target.value);
    };
    const handleFechaFinalChange = (event) => {
        setFechaFinal(event.target.value);
       
    };


    const handlePacienteChange = (selectedOption) => {
        setSelectedPaciente2(selectedOption.value);
        setSelectedPaciente(
            pacienteOptions.find(
                (option) => option.value === selectedOption.value
            )
        );
        if (token) {
            axios
                .get(`/paciente/${selectedOption.value}`, {
                    headers: { Authorization: `Bearer ${token}` },
                })
                .then((response) => {
                    setHabilitado(response.data.Habilitado);
                    setMotivo(response.data.MotivoDeshabilitacion);
                    if (response.data.Habilitado == 0) {
                        Swal.fire({
                            title: "Este paciente se encuentra bloqueado en el hogar de paso",
                            text: `Motivo de bloqueo: ${motivo}`,
                            text: "En caso de dudas por favor contactarse con Administración para desbloquear el cliente",
                            confirmButtonText: "Aceptar",
                        }).then((result) => {
                            if (result.isConfirmed) {
                                window.location.reload();
                            }
                        });
                    }
                    setNombre1Paciente(response.data.Nombre1);
                    setNombre2Paciente(response.data.Nombre2);
                    setApellido1Paciente(response.data.Apellido1);
                    setApellido2Paciente(response.data.Apellido2);
                    setSelectedTipoDocumento(
                        tipoDocumentoOptions.find(
                            (option) =>
                                option.value === response.data.TipoDocumentoId
                        )
                    );
                    setSelectedTipoDocumento2(response.data.TipoDocumentoId);
                    setNumeroIndentificacion(response.data.NumeroIdent);
                    setFechaNacimiento(response.data.FechaNacimiento);
                    setTelPaciente(response.data.TelContacto);
                    setSelectedCiudadOrigen(
                        ciudadOptions.find(
                            (option) =>
                                option.value === response.data.CiudadOrigenId
                        )
                    );
                    setSelectedCiudadOrigen2(response.data.CiudadOrigenId);
                    setEdad(response.data.edad);
                    if (token) {
                        axios
                            .get(
                                `acompanante/paciente/${selectedOption.value}`,
                                {
                                    headers: {
                                        Authorization: `Bearer ${token}`,
                                    },
                                }
                            )
                            .then((response) => {
                                setAcompanante(response.data);
                            })
                            .catch((error) => {
                                console.log(error);
                            });
                    }
                })

                .catch((error) => {
                    console.log(error);
                });
        }
    };


    useEffect(() => {}, [checkedState]);

    // Manejar el clic en el botón "Crear"

    return (
        <Box sx={{ width: "100%", bgcolor: "background.paper" }}>
            <Form>
                <Divider className="mt-4">
                    <Chip label="Solicitud" />
                </Divider>
                <Box>
                    <div className="row d-flex align-items-center justify-content-center">
                        <div className="input-group input-group-sm  d-flex justify-content-center align-items-center">
                           
                            <FormGroup className="col-md-4 mt-3 align-items-center">
                                <TextField
                                    label="Numero de Caso"
                                    className="form-control "
                                    type="Number"
                                    name="NumeroCaso"
                                    value={numeroCaso || ""}
                                    onChange={handleNumeroCasoChange}
                                />
                            </FormGroup>
                        </div>
                    </div>
                    <div className="row d-flex align-items-center justify-content-center">
                        <div className="input-group input-group-sm mb-2 d-flex justify-content-around">
                            <FormControl size="small" className="col-md">
                                <FormGroup>
                                    <TextField
                                        label="Fecha Inicial Servicio"
                                        className="form-control "
                                        type="Date"
                                        name="FechaIncial"
                                        value={fechaInicial}
                                        onChange={handleFechaInicialChange}
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                    />
                                </FormGroup>
                            </FormControl>
                            <FormControl className="col-md">
                                <FormGroup>
                                    <TextField
                                        label="Fecha Final Del Servicio"
                                        className="form-control "
                                        type="Date"
                                        name="FechaFinal"
                                        value={fechaFinal}
                                        onChange={handleFechaFinalChange}
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                    />
                                </FormGroup>
                            </FormControl>
                        </div>
                    </div>
                </Box>
                <Divider className="mb-4">
                    <Chip label="Paciente" />
                </Divider>
                <Box>
                    <div className="row d-flex align-items-center justify-content-center">
                        <div className="input-group input-group-sm mb-2 d-flex justify-content-center align-items-center">
                            <FormControl size="small" className="col-md-10">
                                <FormGroup>
                                    <label htmlFor="solicitudId">
                                        Seleccionar Paciente por Numero de
                                        Identificacion:
                                    </label>
                                    {
                                        <Select
                                            options={pacienteOptions}
                                            onChange={handlePacienteChange}
                                        ></Select>
                                    }
                                </FormGroup>
                            </FormControl>
                        </div>
                    </div>
                    <div className="row d-flex align-items-center justify-content-center flex-wrap">
                        <div className="input-group input-group-sm mb-2 d-flex justify-content-center align-items-center">
                            <FormControl size="small" className="col-md-3">
                                <FormGroup>
                                    <TextField
                                        disabled
                                        label="Primer Apellido Paciente"
                                        className="form-control "
                                        type="Text"
                                        name="Apellido1Paciente"
                                        value={apellido1Paciente}
                                    />
                                </FormGroup>
                            </FormControl>
                            <FormControl size="small" className="col-md-3">
                                <FormGroup>
                                    <TextField
                                        disabled
                                        label="Segundo Apellido Paciente"
                                        className="form-control "
                                        type="Text"
                                        name="Apellido2Paciente"
                                        value={apellido2Paciente}
                                    />
                                </FormGroup>
                            </FormControl>
                            <FormControl size="small" className="col-md-3">
                                <FormGroup>
                                    <TextField
                                        disabled
                                        label="Primer Nombre Paciente"
                                        className="form-control "
                                        type="Text"
                                        name="nombre1Paciente"
                                        value={nombre1Paciente}
                                    />
                                </FormGroup>
                            </FormControl>
                            <FormControl size="small" className="col-md-3">
                                <FormGroup>
                                    <TextField
                                        disabled
                                        label="Segundo Nombre Paciente"
                                        className="form-control "
                                        type="Text"
                                        name="nombre2Paciente"
                                        value={nombre2Paciente}
                                    />
                                </FormGroup>
                            </FormControl>
                            <FormControl size="small" className="col-md-3">
                                <FormGroup>
                                    <label htmlFor="documentoId">
                                        Tipo de Documento
                                    </label>
                                    <Select
                                        isDisabled
                                        options={tipoDocumentoOptions}
                                        value={selectedTipoDocumento}
                                        label="ciudad"
                                    ></Select>
                                </FormGroup>
                            </FormControl>
                            <FormControl size="small" className="col-md-3">
                                <FormGroup>
                                    <TextField
                                        disabled
                                        label="Numero de indentificación"
                                        className="form-control "
                                        type="Text"
                                        name="numeroIdentificacion"
                                        value={numeroIdentificacion}
                                    />
                                </FormGroup>
                            </FormControl>
                            <FormControl size="small" className="col-md-3">
                                <FormGroup>
                                    <TextField
                                        disabled
                                        label="Fecha de Nacimiento"
                                        className="form-control "
                                        type="Date"
                                        name="FechaNacimiento"
                                        value={fechaNacimiento}
                                        InputLabelProps={{
                                            shrink: true,
                                        }}
                                    />
                                </FormGroup>
                            </FormControl>
                            <FormControl size="small" className="col-md-3">
                                <FormGroup>
                                    <TextField
                                        disabled
                                        label="Edad"
                                        className="form-control "
                                        type="text"
                                        name="edad"
                                        value={edad}
                                    />
                                </FormGroup>
                            </FormControl>
                        </div>
                    </div>
                    <div className="row d-flex align-items-center justify-content-center flex-wrap">
                        <div className="input-group input-group-sm mb-2 d-flex justify-content-center align-items-center">
                            <FormControl size="small" className="col-md-3">
                                <FormGroup>
                                    <TextField
                                        disabled
                                        label="Telefono Contacto Paciente"
                                        className="form-control "
                                        type="Number"
                                        name="TelPaciente"
                                        value={telPaciente}
                                    />
                                </FormGroup>
                            </FormControl>
                            <FormControl size="small" className="col-md-3">
                                <FormGroup>
                                    <label htmlFor="ciudadOrigenId">
                                        Ciudad Origen
                                    </label>
                                    <Select
                                        isDisabled
                                        options={ciudadOptions}
                                        value={selectedCiudadOrigen}
                                        label="ciudad"
                                    ></Select>
                                </FormGroup>
                            </FormControl>
                        </div>
                    </div>
                </Box>
                <Divider className="mb-4">
                    <Chip label="Acompañante" />
                </Divider>
                <Box>
                    <div className="row d-flex align-items-center justify-content-center flex-wrap">
                        <div className="input-group input-group-sm mb-2 d-flex justify-content-center align-items-center">
                            {inputsAcom.map((input, index) => (
                                <div
                                    className="input-group input-group-sm mb-2 d-flex justify-content-center align-items-center"
                                    key={input.id}
                                >
                                    <FormControl
                                        size="small"
                                        className="col-md-10"
                                    >
                                        <FormGroup>
                                            <label htmlFor="acompananteId">
                                                Acompañante
                                            </label>
                                            <Select
                                                options={acompananteOptions}
                                                value={{
                                                    label: input.acompananteLabel,
                                                    value: input.acompanante,
                                                }}
                                                onChange={(selectedOption) =>
                                                    handleInputAcompananteChange(
                                                        selectedOption,
                                                        input.id
                                                    )
                                                }
                                                label="acompanante"
                                                name="acompanante"
                                            ></Select>
                                        </FormGroup>
                                    </FormControl>
                                    <FormControl
                                        size="small"
                                        className="col-md-3"
                                    >
                                        <Button
                                            type="button"
                                            onClick={() =>
                                                cancelarInputsAcompanante(index)
                                            }
                                        >
                                            Remove
                                        </Button>
                                    </FormControl>
                                </div>
                            ))}
                            <FormControl size="small" className="col-md-3">
                                <Button
                                    type="button"
                                    onClick={agregarInputAcompanante}
                                >
                                    Añadir acompañante
                                </Button>
                            </FormControl>
                        </div>
                    </div>
                </Box>

                <Box display="flex" justifyContent="center" alignItems="center">
                <Button
                    onClick={handleCreateSubmit}
                    color="primary"
                    otype="submit"
                    disabled={isSubmitting}
                >
                    {isSubmitting ? "Enviando..." : "Enviar Formulario"}
                </Button>
                </Box>
            </Form>
        </Box>
    );
};

export default withTokenCheck(AppForm);
