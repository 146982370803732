import React, { useState, useEffect, useRef } from "react";
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  FormGroup,
} from "reactstrap";
import Select from "react-select";
import axios from "axios";
import Swal from "sweetalert2";

const ModalEdit = ({ isOpen, toggle, title, id, Nombre1Inicial, Nombre2Inicial, Apellido1Inicial, Apellido2Inicial, TipoDocumentoId, NumeroIdentInicial, GeneroId,  FechaNacimientoInicial, TelInicial, CiudadId, DireccionResidencia, TelFijo, ContactoEmergencia, TelEmergencia, token  }) => {
  const [nombre1, setNombre1] = useState("");
  const [nombre2, setNombre2] = useState("");
  const [apellido1, setApellido1] = useState("");
  const [apellido2, setApellido2] = useState("");
  const [tipoDocumento, setTipoDocumento] = useState([]);
  const [selectedTipoDocumento, setSelectedTipoDocumento] = useState("");
  const [selectedTipoDocumento2, setSelectedTipoDocumento2] = useState("");
  const [numeroIdentificacion, setNumeroIndentificacion] = useState("");
  const [fechaNacimiento, setFechaNacimiento] = useState("");
  const [telContacto, setTelContacto] = useState("");
  const [ciudadOrigen, setCiudadOrigen] = useState([]);
  const [selectedCiudadOrigen, setSelectedCiudadOrigen] = useState("");
  const [selectedCiudadOrigen2, setSelectedCiudadOrigen2] = useState("");
  const [direccionResidencia, setDireccionResidencia] = useState("");
  const [telFijo, setTelFijo] = useState("");
  const [contactoEmergencia, setContactoEmergencia] = useState("");
  const [telContactoEmergencia, setTelContactoEmergencia] = useState("");
  const [genero, SetGenero] = useState([]);
  const [selectedGenero, setSelectedGenero] = useState("");
  const [selectedGenero2, setSElectedGenero2] = useState("");
  const [formMode, setFormMode] = useState("none");
  const modalRef = useRef(null);

  useEffect(() => {
    setNombre1(Nombre1Inicial);
    setNombre2(Nombre2Inicial);
    setApellido1(Apellido1Inicial);
    setApellido2(Apellido2Inicial);
    setNumeroIndentificacion(NumeroIdentInicial);
    setTelContacto(TelInicial);
    setDireccionResidencia(DireccionResidencia);
    setTelFijo(TelFijo);
    setContactoEmergencia(ContactoEmergencia);
    setTelContactoEmergencia(TelEmergencia);
  }, [Nombre1Inicial,Nombre2Inicial, Apellido1Inicial,  Apellido2Inicial, NumeroIdentInicial, TelInicial, DireccionResidencia, TelInicial, ContactoEmergencia, TelEmergencia]);

  useEffect(() => {
    if(token){
    axios
      .get("/tipodocumento", { headers: { Authorization: `Bearer ${token}` } })
      .then((response) => {
        setTipoDocumento(response.data);
      })
      .catch((error) => {
        console.log(error);
      });
    }
  }, [token]);

  useEffect(() => {
    if(token){
    axios
      .get("/ciudad", { headers: { Authorization: `Bearer ${token}` } })
      .then((response) => {
        setCiudadOrigen(response.data);
      })
      .catch((error) => {
        console.log(error);
      });
    }
  }, [token]);

  useEffect(() => {
    if(token){
    axios
      .get("/genero", { headers: { Authorization: `Bearer ${token}` } })
      .then((response) => {
        SetGenero(response.data);
      })
      .catch((error) => {
        console.log(error);
      });
    }
  }, [token]);


  const tipoDocumenOptions = tipoDocumento.map((option) => ({
    value: option.id,
    label:
      option.Codigo + " - " + option.Texto ??
      option.Codigo + "-" + option.Texto,
  }));

  const ciudadOptions = ciudadOrigen.map((option) => ({
    value: option.id,
    label: option.Texto ?? option.Texto,
  }));

  const generoOptions = genero.map((option) => ({
    value: option.id,
    label: option.Tipo_Genero ?? option.Tip_Genero,
  }));

  const handleGeneroChange = (selectedOption) => {
    console.log(selectedOption);
    setSElectedGenero2(selectedOption.value);
    setSelectedGenero(
      generoOptions.find((option) => option.value === selectedOption.value)
    );
  };

  const handleNombre1Change = (event) => {
    setNombre1(event.target.value);
  };
  const handleNombre2Change = (event) => {
    setNombre2(event.target.value);
  };
  const handleApellido1Change = (event) => {
    setApellido1(event.target.value);
  };
  const handleApellido2Change = (event) => {
    setApellido2(event.target.value);
  };
  const handleTipoDocumentoChange = (selectedOption) => {
    setSelectedTipoDocumento2(selectedOption.value);
    setSelectedTipoDocumento(
      tipoDocumenOptions.find((option) => option.value === selectedOption.value)
    );
  };
  const handleNumeroIdentChange = (event) => {
    setNumeroIndentificacion(event.target.value);
  };
  const handleFechaNacimientoChange = (event) => {
    setFechaNacimiento(event.target.value);
  };
  const handleTelContactoChange = (event) => {
    setTelContacto(event.target.value);
  };
  const handleCiudadOrigenChange = (selectedOption) => {
    setSelectedCiudadOrigen2(selectedOption.value);
    setSelectedCiudadOrigen(
      ciudadOptions.find((option) => option.value === selectedOption.value)
    );
  };const handleDireccionChange = (event) => {
    setDireccionResidencia(event.target.value);
  };
  const handleTelFijoChange = (event) => {
    setTelFijo(event.target.value);
  };
  const handleContactoEmergenciaChange = (event) => {
    setContactoEmergencia(event.target.value);
  };
  const handleTelContactoEmergenciaChange = (event) => {
    setTelContactoEmergencia(event.target.value);
  };

  const handleEditSubmit = (event) => {
    event.preventDefault();
    axios
      .put(`/paciente/${id}`, {
        Nombre1: nombre1 ? nombre1 : Nombre1Inicial,
        Nombre2: nombre2 ? nombre2 : Nombre2Inicial,
        Apellido1: apellido1 ? apellido1 : Apellido2Inicial,
        Apellido2: apellido2 ? apellido2 : Apellido2Inicial,
        TipoDocumentoId: parseInt(selectedTipoDocumento2) ? parseInt(selectedTipoDocumento2) : parseInt(TipoDocumentoId),
        NumeroIdent: parseInt(numeroIdentificacion) ? parseInt(numeroIdentificacion) : parseInt(NumeroIdentInicial),
        FechaNacimiento: fechaNacimiento ? fechaNacimiento : FechaNacimientoInicial,
        TelContacto: telContacto ? telContacto : TelInicial,
        CiudadOrigenId: parseInt(selectedCiudadOrigen2) ? parseInt(selectedCiudadOrigen2) : parseInt(CiudadId),
        DireccionResidencia: direccionResidencia,
        TelFijo : telFijo,
        ContEmergencia: contactoEmergencia,
        TelEmergencia: telContactoEmergencia,
        GeneroId : parseInt(selectedGenero2) ? parseInt(selectedGenero2) : parseInt(GeneroId)
      }, {headers: { Authorization: `Bearer ${token}` }})
      .then((response) => {
        Swal.fire({
          icon: "success",
          title: "Actualizado!",
          text: "el paciente a sido actualizada con exito.",
          showCloseButton: true,
        }).then(() => {
          window.location.reload();
        });
      })
      .catch((error) => {
        Swal.fire({
          icon: "error",
          title: "Error!",
          text: "el paciente no se pudo actualizar",
          showCloseButton: true,
        });
      });
  };

  return (
    <Modal ref={modalRef} isOpen={isOpen} toggle={toggle}>
      <ModalHeader toggle={toggle}>{title}</ModalHeader>
      <ModalBody>
        <FormGroup>
          <label>Primer Nombre:</label>
          <input
            className="form-control"
            name="nombre1"
            type="text"
            onChange={handleNombre1Change}
            value={nombre1}
          />
        </FormGroup>
        <FormGroup>
          <label>Segundo Nombre:</label>
          <input
            className="form-control"
            name="nombre2"
            type="text"
            onChange={handleNombre2Change}
            value={nombre2}
          />
        </FormGroup>
        <FormGroup>
          <label>Primer Apellido:</label>
          <input
            className="form-control"
            name="apellido1"
            type="text"
            onChange={handleApellido1Change}
            value={apellido1}
          />
        </FormGroup>
        <FormGroup>
          <label>Segundo Apellido:</label>
          <input
            className="form-control"
            name="apellido2"
            type="text"
            onChange={handleApellido2Change}
            value={apellido2}
          />
        </FormGroup>
        <FormGroup>
          <label htmlFor="TipoDocumentoId">Tipo de Documento:</label>
          <Select
            options={tipoDocumenOptions}
            value={selectedTipoDocumento ? selectedTipoDocumento : tipoDocumenOptions.find((option) => option.value === TipoDocumentoId)}
            onChange={handleTipoDocumentoChange}
          ></Select>
        </FormGroup>
        <FormGroup>
          <label>Numero de Documento:</label>
          <input
            className="form-control"
            name="numeroIdentificacion"
            type="text"
            onChange={handleNumeroIdentChange}
            value={numeroIdentificacion}
          />
        </FormGroup>
        <FormGroup>
          <label>Fecha de Nacimiento:</label>
          <input
            className="form-control"
            name="fechaNacimiento"
            type="date"
            onChange={handleFechaNacimientoChange}
            value={fechaNacimiento ? fechaNacimiento : FechaNacimientoInicial}
          />
        </FormGroup>
        <FormGroup>
          <label htmlFor="genero">Genero:</label>
          <Select
            options={generoOptions}
            value={selectedGenero ? selectedGenero : generoOptions.find((option) => option.value === GeneroId)}
            onChange={handleGeneroChange}
          ></Select>
        </FormGroup>
        <FormGroup>
          <label>Telefono de Contacto:</label>
          <input
            className="form-control"
            name="numeroContacto"
            type="text"
            onChange={handleTelContactoChange}
            value={telContacto}
          />
        </FormGroup>
        <FormGroup>
          <label htmlFor="CiudadOrigenId">Ciudad de Origen:</label>
          <Select
            options={ciudadOptions}
            value={selectedCiudadOrigen ? selectedCiudadOrigen : ciudadOptions.find((option) => option.value === CiudadId)}
            onChange={handleCiudadOrigenChange}
          ></Select>
        </FormGroup><FormGroup>
          <label htmlFor="DireccionResidencia">Dirección Residencia:</label>
          <input
            className="form-control"
            name="DireccionResidencia"
            type="text"
            value={direccionResidencia}
            onChange={handleDireccionChange}
          />
        </FormGroup>
        <FormGroup>
          <label htmlFor="TelFijo">Telefono Fijo:</label>
          <input
            className="form-control"
            name="TelFijo"
            type="text"
            value={telFijo}
            onChange={handleTelFijoChange}
          />
        </FormGroup>
        <FormGroup>
          <label htmlFor="ContactoEmergencia">Contacto Emergencia:</label>
          <input
            className="form-control"
            name="ContactoEmergencia"
            type="text"
            value={contactoEmergencia}
            onChange={handleContactoEmergenciaChange}
          />
        </FormGroup>
        <FormGroup>
          <label htmlFor="TelContacto"> Telefono Contacto Emergencia:</label>
          <input
            className="form-control"
            name="TelContactoEmergencia"
            type="text"
            value={telContactoEmergencia}
            onChange={handleTelContactoEmergenciaChange}
          />
        </FormGroup>
      </ModalBody>

      <ModalFooter>
        <Button onClick={handleEditSubmit} color="primary" otype="submit">
          Guardar
        </Button>
        <Button color="danger" onClick={() => setFormMode("none")}>
          Cancelar
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default ModalEdit;
