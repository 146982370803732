import React, { useState, useEffect, useRef } from "react";
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  FormGroup,
} from "reactstrap";
import axios from "axios";
import Swal from "sweetalert2";

const ModalCreate = ({
  isOpen,
  toggle,
  title,
  token,
}) => {
  const [formMode, setFormMode] = useState("none");
  const [genero, setGenero] = useState("");
  const modalRef = useRef(null);

  const handleGeneroChange = (event) => {
    setGenero(event.target.value);
  };


  const handleCreateSubmit = (event) => {
    event.preventDefault();
    axios
      .post("/genero", {
        Tipo_Genero: genero,
      }, {headers: { Authorization: `Bearer ${token}` }})
      .then((response) => {
        Swal.fire({
          icon: "success",
          title: "Creado!",
          text: "el genero ha sido registrado con exito",
          showCloseButton: true,
        }).then(() => {
          window.location.reload();
        });
      })
      .catch((error) => {
        Swal.fire({
          icon: "error",
          title: "Error!",
          text: "Ha ocurrido un error al registrar el genero.",
          showCloseButton: true,
        });
      });
  };

  return (
    <Modal ref={modalRef} isOpen={isOpen} toggle={toggle}>
      <ModalHeader toggle={toggle}>{title}</ModalHeader>
      <ModalBody>
        <FormGroup>
          <label>Genero:</label>
          <input
            className="form-control"
            name="rol"
            type="text"
            value={genero}
            onChange={handleGeneroChange}
          />
        </FormGroup>
      </ModalBody>

      <ModalFooter>
        <Button onClick={handleCreateSubmit} color="primary" otype="submit">
          Crear
        </Button>
        <Button color="danger" onClick={() => setFormMode("none")}>
          Cancelar
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default ModalCreate;
